import React, { Component } from 'react'
import './CustomCarousel.scss'
import PageHeader from './PageHeader'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

export class CustomCarousel extends Component {
  constructor(props) {
    super(props)
    this.slideDelay = 10000
    this.state = {
      x: 0,
      transition: '.5s',
      transitionDefault: '.5s'
    }
    this.sliderArr = this.props.bannerData.map((item, index) => {
      return (
        <PageHeader
          key={index}
          large
          title={item.title}
          subtitle={item.subtitle}
          backgroundImage={item.image}
        />
      )
    })
    this.sliderTimeout = null
  }

  componentDidMount() {
    clearTimeout(this.sliderTimeout)
    this.sliderTimeout = setTimeout(this.goRight, this.slideDelay)
  }

  componentDidUpdate() {
    clearTimeout(this.sliderTimeout)
    this.sliderTimeout = setTimeout(this.goRight, this.slideDelay)
  }

  componentWillUnmount(){
    clearTimeout(this.sliderTimeout)
  }

  goLeft = () => {
    if (this.state.x === 0) {
      this.setState({ x: -(this.sliderArr.length - 1) * 100 })
    } else {
      this.setState({ x: this.state.x + 100 })
    }
  }

  goRight = () => {
    if (this.state.x === -(this.sliderArr.length - 1) * 100) {
      this.setState({ x: 0 })
    } else {
      this.setState({ x: this.state.x - 100 })
    }
  }

  render() {
    return (
      <div className="slider">
        {this.sliderArr.map((item, index) => {
          return (
            <div
              key={index}
              className="slide"
              style={{
                transform: `translateX(${this.state.x}%)`,
                transition: this.state.transition
              }}
            >
              {item}
            </div>
          )
        })}
        <button id="goLeft" onClick={this.goLeft}>
          <FaChevronLeft className="arrow" />
        </button>
        <button id="goRight" onClick={this.goRight}>
          <FaChevronRight className="arrow" />
        </button>
      </div>
    )
  }
}

export default CustomCarousel
