import React from 'react'
import { graphql } from 'gatsby'

// import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Layout from '../components/Layout'
import CustomCarousel from '../components/CustomCarousel'
import GoogleMap from '../components/GoogleMap'
import Gallery from 'react-photo-gallery'
import { FaSearch, FaCalendarAlt } from 'react-icons/fa'
import { Link } from '@reach/router'
import NavLink from '../components/NavLink'

// Export Template for use in CMS preview
export const HomePageTemplate = ({
  title,
  subtitle,
  featuredImage,
  body,
  bannerData,
  gallery
}) => (
  <main className="Home">
    {/* <PageHeader
      large
      title={title}
      subtitle={subtitle}
      backgroundImage={featuredImage}
    /> */}

    <CustomCarousel bannerData={bannerData} />

    <section className="section">
      <div className="container">
        <Content source={body} />
        <div style={{ padding: '1rem', textAlign: 'center' }}>
          <Link className="Button homePageButtons active" to="/products/?s=">
            <FaSearch /> Search Products
          </Link>
          <Link className="Button homePageButtons active" to="/events/">
            <FaCalendarAlt /> Events
          </Link>
        </div>
        {/* <Gallery photos={gallery} className="galleryClass" /> */}
      </div>
    </section>

    <GoogleMap />
  </main>
)

// Export Default HomePage for front-end
const HomePage = ({ data: { page } }) => (
  <Layout meta={page.frontmatter.meta || false}>
    <HomePageTemplate
      {...page}
      {...page.frontmatter}
      body={page.html}
      bannerData={page.frontmatter.banner}
      gallery={page.frontmatter.gallery}
    />
  </Layout>
)

export default HomePage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        featuredImage
        banner {
          title
          subtitle
          image
        }
        gallery {
          src
          width
          height
        }
      }
    }
  }
`
